import * as Styles from './LoginContainer.styles';
import { I18N } from '../../internationalization/AppText';
import { tracker } from '@visikon/tracker/src';
import { useAuthenticateByCode } from '../../api/auth-api';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers/reducer';
import { Spinner } from '../../common/Spinner';
import { useEffect, useState, useRef } from 'react';
import { setError } from '../../actions/resourcesActions';
import { GetButton } from '../program/SmartBanner.styles';
import { CodeInputField } from '@visikon/spine';
import { CodeHelp } from './CodeHelp';
import { useBackbone } from '@visikon/backbone';

export const CODE_INPUT_LENGTH = 8;
const LOGIN_ERROR_PREFIX = 'login_error_';
export const MERGE_ERROR_PREFIX = 'merge_error_';
export const MERGE_ERROR_NEW_LANGUAGE = `${MERGE_ERROR_PREFIX}new_language`;
export const ERROR_TOO_FEW_CHARACTERS: TextKeys = `${LOGIN_ERROR_PREFIX}too_few_characters`;

const CODEINPUT_HTML_ID = 'codeinput';

type Props = {
  verifyCode: (code: string) => void;
  codeVerified?: boolean;
  dontShowStaffLink?: boolean;
};

export const ErrorMessage = (error: string | undefined) => {
  if (error) {
    console.log('Error: ', error);
    const parsedError = error.startsWith(MERGE_ERROR_PREFIX) || error.startsWith(LOGIN_ERROR_PREFIX) ? error : LOGIN_ERROR_PREFIX + error;
    const errorText = parsedError as TextKeys;
    return (
      <Styles.ErrorMessage role="alert">
        <I18N text={errorText} />
      </Styles.ErrorMessage>
    );
  }
  return <></>;
};

export function EndUserLoginV2(props: Props) {
  const selfServiceUrl = useBackbone((store) => store.hosts.selfService);
  const dispatch = useDispatch();
  const loginProcess = useSelector((state: IState) => state.auth.loginProcess);
  const [code, setCode] = useState('');
  const { mutate: authenticate, isLoading } = useAuthenticateByCode();
  const error = useSelector((state: IState) => state.resources.error);
  const loading = (isLoading || (loginProcess?.authCode !== undefined && error !== undefined)) as boolean;

  useEffect(() => {
    let didCancel = false;

    if (!didCancel && props.codeVerified) {
      console.log('Authenticating');
      authenticateUser();
    }

    return () => {
      didCancel = true; // Set the flag to prevent state updates on an unmounted component
    };
  }, [props.codeVerified]);

  useEffect(() => {
    if (loginProcess?.authCode) {
      setCode(loginProcess.authCode);
      login(loginProcess.authCode);
    }
  }, [loginProcess]);

  useEffect(() => {
    dispatch(setError(undefined));
  }, []);

  const authenticateUser = () => {
    tracker.trackEvent('Auth', 'loginPress', 'endUser');
    authenticate({ code });
  };

  const login = (excplitCode?: string) => {
    let codeToCheck = excplitCode ?? code;
    if (codeToCheck.length < CODE_INPUT_LENGTH) {
      dispatch(setError(ERROR_TOO_FEW_CHARACTERS));
      return;
    }

    props.verifyCode(codeToCheck);
  };

  const handleCodeChange = (code: string) => {
    setCode(code);

    if (error) {
      dispatch(setError(undefined));
    }
  };

  return (
    <div style={{ ...Styles.FlexColumnCenter, width: '100%', minWidth: '340px' }}>
      {loading && !error ? (
        <Spinner />
      ) : (
        <>
          <Styles.CodeInputLabel htmlFor={CODEINPUT_HTML_ID}>
            <I18N text="enter_personal_code" />
          </Styles.CodeInputLabel>

          <Styles.SpinnerCodeField>
            <CodeInputField htmlId={CODEINPUT_HTML_ID} loading={loading} error={!!error} code={code} onChange={handleCodeChange} login={login} />
          </Styles.SpinnerCodeField>

          {error && ErrorMessage(error)}
          <Styles.ActionButtonBox>
            <GetButton onClick={() => login()} disabled={loading} style={loading ? { cursor: 'default' } : { cursor: 'pointer' }}>
              <I18N text="continue" />
            </GetButton>
          </Styles.ActionButtonBox>

          <Styles.GuidingBox>
            <CodeHelp />
            {!props.dontShowStaffLink && (
              <Styles.StaffLink href={selfServiceUrl}>
                <I18N text="staff_login_link" />
              </Styles.StaffLink>
            )}
          </Styles.GuidingBox>
        </>
      )}
    </div>
  );
}
